<template>
<div>
  <Modal
      v-model="activationStatus"
      :title="$t('trainingcamp_train_avtive')"
      width="700px"
      footer-hide
      @on-cancel="cancel"
  >
    <div class="activation-cont" v-if="activationList.length">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_quick') }}</p>
      <div class="activation-cont-item" v-for="item in activationList" :key="item.id">
        <div>
          <p>{{ item.code }}</p>
          <p>{{ timelongsObj[item.timelong] }}</p>
        </div>
        <p class="activation-cont-item-btn" @click="activeCode(item.code)">{{ $t('trainingcamp_train_avtive_btn') }}</p>
      </div>
    </div>
    <div class="activation-cont">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_manual') }}</p>
      <div class="activation-cont-item">
        <div>
          <Input v-model="codekey" size="large" :placeholder="$t('trainingcamp_train_code_placeholder')" style="width: 300px;" @on-change="changeCodeKey" @on-blur="getCodeInfo" />
          <p v-if="codeInfo.code">{{this.timelongsObj[codeInfo.timelong]}}</p>
        </div>
        <p class="activation-cont-item-btn" @click="activeCode(codekey,1)">{{ codeInfo.code ? $t('trainingcamp_train_avtive_confirm') : $t('trainingcamp_train_avtive_btn') }}</p>
      </div>
    </div>
    <div class="activation-cont">
      <p class="activation-cont-tit">{{ $t('trainingcamp_train_avtive_get') }}</p>
      <div class="get-code">
        <img src="../assets/images/icon/wechat.png" width="20" height="20" />
        <p>{{ activationSite && activationSite.contact_wx }}</p>
        <!--        <span>({{ $t('trainingcamp_train_wx') }})</span>-->
      </div>
      <div class="get-code">
        <img src="../assets/images/icon/telephone.png" width="20" height="20" />
        <p>{{ activationSite && activationSite.contact_mobile }}</p>
        <!--        <span>({{ $t('trainingcamp_train_wx') }})</span>-->
      </div>
      <img :src="activationSite && activationSite.contact_wx_qrcode" width="172" height="172" />
      <p class="get-code-wx" v-if="activationSite.contact_wx_qrcode">{{ $t('trainingcamp_train_wx_add') }}</p>
    </div>
  </Modal>
</div>
</template>

<script>
export default {
  name: "activationModal.vue",
  data(){
    return {
      // activationModal:false, //激活弹窗
      timelongsObj:{},
      activationSite:{},
      activationList:{},
      codekey:'', //输入的激活码
      codeInfo:{},
      activationStatus:false,
    }
  },
  props:{
    categoryId:{
      type:String,
      default:'',
    },
    activationModal:{
      type:Boolean,
      default: true,
    }
  },
  watch:{
    activationModal:{
      handler(newVal, oldVal) {
        this.activationStatus = newVal;
      },
      deep: true,
      immediate: true
    }
  },
  created(){
    this.getActiveForm();
  },
  methods:{
    cancel(){
      console.log('121212')
      this.$emit('close')
    },
    getActiveForm(){
      let params = {
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActiveForm(params).then((res)=>{
        this.timelongsObj = res.timelongs;
        this.activationList = res.list;
        this.activationSite = res.site;
      })
    },
    changeCodeKey(){
      this.codeInfo = {};
    },
    getCodeInfo(){  //获取code详情
      if(!this.codekey){
        return;
      }
      let params = {
        code:this.codekey,
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActive(params).then((res)=>{
        this.codeInfo = res.info;
      })
    },
    activeCode(data,type){ //激活
      if(!data){
        this.$Message.warning(this.$t('trainingcamp_train_code_placeholder'));
        return;
      }
      if(type == 1 && !this.codeInfo.code){
        this.$Message.warning(this.$t('trainingcamp_train_avtive_confirm_message'));
        return;
      }
      let params = {
        code:data,
        is_sure:'1',
        category_id:this.categoryId
      };
      this.api.codekey.cdkeyActive(params).then((res)=>{
        this.$Message.success(this.$t('trainingcamp_train_avtive_success'));
        // this.activationModal = false;
        this.$emit('success');
      })
    },
  }
}
</script>

<style scoped lang="scss">
.activation-cont{
  margin-bottom: 20px;
  padding: 20px;
  background: #f8f8f9;
  border-radius: 4px;
  font-size: 14px;

  .activation-cont-tit{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .activation-cont-item{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 36px;
      line-height: 36px;

      >p{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        height: 100%;
      }
      >p:nth-child(1){
        width: 300px;
        text-align: center;
      }
      >p:nth-child(2){
        width: 150px;
        text-align: center;
      }
    }
    .activation-cont-item-btn{
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background-color: #2d8cf0;
      color:#FFFFFF;
      border-radius: 4px;
      cursor: pointer;
    }

  }
  .get-code{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >p{
      padding: 0 5px;
      color: #2d8cf0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .get-code-wx{
    margin-top: 10px;
    width: 172px;
    text-align: center;
  }
}
</style>
