<template>
  <div style="position: relative;min-height: 300px">
    <div  class="data-detail" v-if="similarData.id">
      <p class="data-type" :class="similarData.type == 1 ? 'bjFD0' : similarData.type == 2 ? 'purple' : 'bj3ff' ">{{ typesObj[similarData.type] }}</p>
      <Richtxt :dataObj="{val:similarData.title}" :isDisabled="true" :isInline="true"></Richtxt>
      <div class="options" v-for="(item,index) in similarData.options">
        <div><span>{{optionIndex[index]}}.</span><Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt></div>

      </div>
      <div class="answer">
        <div class="mb20">
          <span class="tit">正确答案：</span>{{correctAnswer.join('')}}
        </div>
        <div v-if="similarData.explain">
          <p class="tit">题目解析：</p>
          <p ><Richtxt :dataObj="{val:similarData.explain}" :isInline="true" :isDisabled="true"></Richtxt></p>
        </div>
      </div>
      <div>
        <div class="mb20">
          <span class="tit">难度：</span>{{levelObj[similarData.level]}}
        </div>
<!--        <div>-->
<!--          <p class="tit">考点：</p>-->
<!--          <div class="nodes">-->
<!--            <p v-for="item in info.labelKeypoints" :key="item.id">{{ item.label.name }}</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>
    <Spin v-else fix></Spin>
  </div>


</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "dataDetail",
  data(){
    return {
      info:{},
      optionIndex:['A','B','C','D','E','F'],
      correctAnswer:[],
    }
  },
  props:{
    similarData:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    typesObj:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    levelObj:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    similarData:{
      handler(newV){
        if(newV.id){
          this.correctAnswer = [];
          newV.options.forEach((item,index)=>{
            if(item.is_correct == '1'){
              this.correctAnswer.push(this.optionIndex[index]);
            }
          })
        }
      },
      immediate:true
    }
  },
  components:{
    Richtxt
  },
  created(){

  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.data-detail{
  padding: 0 20px;
  font-size: 14px;
  color: #333333;
  .data-type{
    margin-bottom: 5px;
    width: 40px;
    height: 26px;
    border-radius: 4px;
    text-align: center;
    line-height: 26px;
  }
  .options{
    margin: 20px 0;
    line-height: 24px;
    >div{
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      >span{
        width: 20px;
        display: inline-block;
      }
    }
  }
  .answer{
    margin-bottom: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #E8EAEC;
    border-top: 1px solid #E8EAEC;

  }
  .nodes{
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    >p{
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 12px;
      height: 26px;
      background: #EEEEEE;
      border-radius: 4px;
      color: #999999;
      line-height: 26px;
    }
  }
  .tit{
    font-weight: bold;
  }
  .mb20{
    margin-bottom: 20px;
  }

}
</style>
